// nombre formato -> apellidos, nombre
export const acortarIniciales = (nombre) => {
    const nombreSeparado = nombre.split(",")
    const nombres = nombreSeparado[1].split(" ")

    let nombreFinal = nombreSeparado[0] + ","
    nombres.map((n, i) => {
        if(i !== 0){
            nombreFinal += " " + n.charAt(0) + "."
        }
    })

    return nombreFinal
}