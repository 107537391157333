import React, { useState, useEffect } from 'react'
import Tarjeta from '../components/OnTheTee/Tarjeta'
import '../styles/OnTheTee.css'

const OnTheTee = ({ fecha, jornada, partidas, limite }) => {

    const [refrescar, setRefrescar] = useState(false) // Necesario para recargar el componente cada cierto tiempo
    const [finalizado, setFinalizado] = useState(false) // Al finalizar todas las salidas se eliminara el componente
    const [intervalId, setIntervalId] = useState(null) // Se almacena aqui el id del intervalo porque de otra manera no seria accesible desde la funcion "mostrarSalidas"

    // Refresca el componente cada 30000 milisegundos (30 segundos)
    useEffect(() => {
        const intvId = setInterval(() => {
            setRefrescar((prevRefrescar) => !prevRefrescar)
        }, 30000)

        // Guardamos aqui el id para posteriormente borrar el intervalo cuando queramos
        setIntervalId(intvId)

        // Limpia el intervalo cuando el componente se desmonta
        return () => clearInterval(intvId)
    }, [])

    const mostrarSalidas = () => {
        // Contador para controlar el limite de partidas que se muestran
        let mostradas = 0
        // Epoch para comparar con las salidas
        const ahora = Date.now() / 1000

        const tarjetas = partidas.map(({ hora, tee, jugadores, epoch }, i) => {
            if (ahora < Number(epoch)) {
                if (mostradas < limite) {
                    mostradas++
                    return (
                        <Tarjeta
                            key={i}
                            hora={hora}
                            tee={tee}
                            jugadores={jugadores}
                        />
                    )
                }
            }
        })

        if (mostradas === 0 && intervalId) {
            clearInterval(intervalId)
            setFinalizado(true)
        }

        return tarjetas
    }

    return (
        <>
            {
                !finalizado &&
                    <div className="ttCard">
                        <div className="ttCardContent alternate">
                            {/* <h1 className="title">On the tee</h1>
                            <h2 className="ttClub"> { fecha + " (vuelta " + jornada + ")" } </h2> */}
                            <div className="tarjetasContent">
                                { mostrarSalidas() }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}


export default OnTheTee