import React, { useState, useEffect } from 'react'
import ReactWeather, { useVisualCrossing } from 'react-open-weather'

const Weather = ({ latitud, longitud }) => {
    const [coordenadasNuevas, setCoordenadasNuevas] = useState({ lat: latitud, lon: longitud })
    const [reload, setReload] = useState(false) // Cambia de valor para recargar el componente

    useEffect(() => {
        setInterval(() => {
            setReload(prevReload => !prevReload)
        }, 3600000) // 1 hora
    }, [])

    const { data, isLoading, errorMessage } = useVisualCrossing({
        key: '8TRH4GU8FX9J8HQLRNNSPQ3QV',
        lat: coordenadasNuevas.lat,
        lon: coordenadasNuevas.lon,
        lang: 'es',
        unit: 'metric', // values are (metric,us,uk)
    })

    const cambiarCoordenadas = (coordenadas) => {
        setCoordenadasNuevas(coordenadas)
    }

    const customStyles = {
        fontFamily: 'Helvetica, sans-serif',
        gradientStart: '#0181C2',//Arriba izquierda
        gradientMid: '#04A7F9', //Centro
        gradientEnd: '#4BC4F7', //Parte derecha
        locationFontColor: '#FFF',
        todayTempFontColor: '#FFF',
        todayDateFontColor: '#B5DEF4', //Primera fecha
        todayRangeFontColor: '#B5DEF4',
        todayDescFontColor: '#B5DEF4',
        todayInfoFontColor: '#B5DEF4',
        todayIconColor: '#FFF', //Icono del dia de hoy
        forecastBackgroundColor: '#FFF', //Fondo de abajo
        forecastSeparatorColor: '#DDD',
        forecastDateColor: '#777', //El color de la fecha de abajo
        forecastDescColor: '#777',
        forecastRangeColor: '#777',
        forecastIconColor: '#4BC4F7', //Icono dia futuros
    }

    return (
        <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="es"
            // locationLabel={ciudadSeleccionada ? ciudadSeleccionada.nombre : 'Sevilla'}
            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
            showForecast={false}
            theme={customStyles}
        />
    )
}

export default Weather