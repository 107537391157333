import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import axios from 'axios'
import "../styles/Livescoring.css"
import { acortarIniciales } from '../util/acortar'
import { useAutoAnimate } from "@formkit/auto-animate/react"

const Livescoring = ({
    jornadas, // Array principal de informacion
    total, // Resultados totales
    jornadaId,
    calculo, // Calculo de la competicion
    ordenClaves, // Orden en el que aparecen los jugadores en livescoring
    ordenPosicion,
    ordenJornada,
    jornadasCompatibles,
    limite, // Numero de filas que se muestran en livescoring, 0 para deshabilitar limite
    compId,
    section, // Seccion en la que se esta usando el componente
    nombreClub,
    nombreCompeticion,
    horaSalida
}) => {

    const [animationParent] = useAutoAnimate()

    // Tanto jornadas como el total se pasan a hooks para que se puedan actualizar cuando diga el socket
    const [dias, setDias] = useState(jornadas)
    const [suma, setSuma] = useState(total)
    const [ordenTarjetas, setOrdenTarjetas] = useState(ordenClaves)
    const [jornadaActiva, setJornadaActiva] = useState(jornadaId) // Este hook se creo para poder alternar entre jornadas mediante pestañas, pero para cambiar de jornada se hace por url asi que se deja asi por si se necesita en el futuro
    const [ordenPos, setOrdenPos] = useState(ordenPosicion)

    let tarjetasMostradas = 0 // Controla cuantas tarjetas se llevan mostradas para parar el bucle segun el limite establecido

    // Listen for "jornadas" changes to set data when parent component update data
    useEffect(() => {
        setDias(jornadas)
        setSuma(total)
        setOrdenTarjetas(ordenClaves)
        setOrdenPos(ordenPosicion)
    }, [jornadas])

    useEffect(() => {
        if ("index" !== section) {
            // Conectarse al servidor Socket.io
            const socket = io('https://ws.nextcaddy.com:29920')

            // Escuchar eventos del servidor
            socket.on('new message', (response) => {
                if(response.message.tipo === "resultado" && response.message.competicion === parseInt(compId)){
                    callsReload() // Como se ha detectado una nueva llamada se hace una recarga para obtener la nueva
                }
            })

            return () => {
                socket.disconnect()
            }
        }
    }, [])

    const callsReload = () => {
        axios.post(window.routeInfo.livescoringReload, {
            'jornada': jornadaActiva
        })
        .then((response) => {
            setDias(response.data[0])
            setSuma(response.data[1])
            setOrdenTarjetas(response.data[2])
            setOrdenPos(response.data[3])
        })
    }

    // Devuelve el elemento del contrapar con el estilo adecuado segun su valor
    const mostrarContrapar = (contrapar, especial, tipo) => {
        let stateClass = "res "
        let num = contrapar

        if(especial == null){
            if(contrapar > 0){
                stateClass += "over"
                num = "+" + contrapar
            } else if (contrapar < 0) {
                stateClass += "under"
            } else if (contrapar == 0) {
                stateClass += "par"
                num = "P"
            } else {
                stateClass += "empty"
                num = "-"
            }
        } else {
            if(tipo === "jornada"){
                return ""
            }

            stateClass += "especial"
            num = especial
        }

        return <div className={stateClass}>{ num }</div>
    }

    // Muestra el hoyo por donde va, F si ha finalizado, vacio si no hay informacion o la hora de salida si todavia no ha salido
    const hoyoValue = (j, clave) => {
        let hoyoVal = ''

        if(j.especial == null){
            if(horaSalida && horaSalida[clave]){
                hoyoVal = horaSalida[clave]
            }

            if(j.tarjetaEntregada){
                hoyoVal = 'F'
            } else if (j.ultimoHoyo != null) {
                hoyoVal = 'H' + j.ultimoHoyo
            }
        }

        return hoyoVal
    }

    const renderRow = (orden) => {
        const jugador = dias[jornadaActiva][orden]

        if (jugador && (tarjetasMostradas < limite || limite == 0)) {

            tarjetasMostradas++

            return <div className='fila' key={orden}>
                <div className='zonaJugador'>
                    <label className='posicion'>{ ordenPos[orden] }</label>
                    <div className='jugador'>
                        {
                            !jugador.team ?
                                jugador.nombre.map((nombre, i) => {
                                    return (
                                        <label key={i} className='nombre'>{ section === "livescoring" ? nombre : acortarIniciales(nombre) }</label>
                                    )
                                })

                                :

                                jugador.nombre
                        }
                        {/*DE MOMENTO SE OCULTAN LOS JUGADORES DEL EQUIPO*/}
                        {
                            jugador.team &&
                                <small style={{fontSize: '9pt'}}>
                                    {
                                        jugador.jugadores.join(' - ')
                                    }
                                </small>
                        }
                    </div>
                </div>
                <div className='columnaPuntuacion'>
                    <div className='puntuacion'>
                        <label className='hoyo'>{ hoyoValue(jugador, orden) }</label>
                        <div className="puntuacionHoy">
                            { mostrarContrapar(jugador.contrapar, jugador.especial, "jornada") }
                            {
                                jugador.tarjetaEntregada &&
                                    <div className="current finished">{ calculo === "H" ? jugador.hcp : jugador.scratch }</div>
                            }
                        </div>
                        {
                            ordenJornada != 1 && jornadasCompatibles === true &&
                                <div className='puntuacionTotal'>
                                    { mostrarContrapar(suma[jugador.clave].contrapar, suma[jugador.clave].especial, "total") }
                                    {
                                        jugador.tarjetaEntregada &&
                                        <div
                                            className='current finished'>{ calculo === "H" ? suma[jugador.clave].hcp : suma[jugador.clave].scratch }</div>
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
        }
    }

    return (
        <>
            {
                Object.values(dias).length > 0 ?
                    <div className={"livescoringComponent" + (section == 'livescoring' ? " big" : "")}>
                        <div className="livescoringComponentContent">
                            <div className="livTitle">{ section == 'index' ? nombreCompeticion : 'Livescoring' }</div>
                            {
                                section == 'index' &&
                                    <div className="scClub">{ nombreClub }</div>
                            }
                            <div className='liv-head'>
                                <div className='liv-head-item tj'>J{ ordenJornada }</div>
                                {
                                    ordenJornada != 1 && jornadasCompatibles === true &&
                                        <div className='liv-head-item tt'>T</div>
                                }
                            </div>
                            <div className='lista'>
                                <div className={'podium' + (ordenTarjetas.length > 12 ? ' separatorShadow' : '')} ref={animationParent}>
                                    {
                                        ordenTarjetas.map((orden, i) => {
                                            if (i < 3) {
                                                return renderRow(orden)
                                            }
                                        })
                                    }
                                </div>
                                <div id='liveScroll' className='queue' ref={animationParent}>
                                    {
                                        ordenTarjetas.map((orden, i) => {
                                            if (i >= 3) {
                                                return renderRow(orden)
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    :

                    <div className={'emptyLivescoring' + (section == 'livescoring' ? " big" : "")}>
                        {
                            /* Hide message on */
                            'index' !== section &&
                                <h1>Esperando Livescoring</h1>
                        }
                    </div>
            }
        </>
    )
}


export default Livescoring
