import React, { useState, useEffect } from 'react'
import "../styles/Plazas.css"

// !!! Falta introducir socket para que se actualice el numero de inscritos !!!
const Plazas = ({
    nombreCompeticion,
    nombreClub,
    fechaCompeticion,
    fechaFin,
    plazasTotales,
    plazasOcupadas,
    listaEspera,
    seccion
}) => {
    const competicionFecha = () => {
        const fecha = new Date(fechaCompeticion)
        const mes = fecha.getMonth()
        const dia = fecha.getDate()
        const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

        return <div className='fechas'>
            <label className='fecha'>{ dia }</label>
            <label className='mes'>{ meses[mes] }</label>
        </div>
    }

    return (
        <div className='plazas'>
            {
                (listaEspera && plazasOcupadas > 0) &&
                <div className='listaEspera'>
                    <label>lista de espera</label>
                </div>
            }

            <div className='main'>
                <div className='zonaFecha'>
                    { competicionFecha() }
                </div>
                <div className="zonaTexto">
                    <div>
                        <h1 className='nombreComp'>{ nombreCompeticion }</h1>
                        <h2 className='nombreClub'>{ nombreClub }</h2>
                        <div className='info'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 12q-1.65 0-2.825-1.175T8 8q0-1.65 1.175-2.825T12 4q1.65 0 2.825 1.175T16 8q0 1.65-1.175 2.825T12 12m-8 8v-2.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20z"/></svg>
                            <label className='infoPlazas'>{ plazasOcupadas }/{ plazasTotales }</label>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M10 3q-.425 0-.713-.288T9 2q0-.425.288-.713T10 1h4q.425 0 .713.288T15 2q0 .425-.288.713T14 3h-4Zm2 11q.425 0 .713-.288T13 13V9q0-.425-.288-.713T12 8q-.425 0-.713.288T11 9v4q0 .425.288.713T12 14Zm0 8q-1.85 0-3.488-.713T5.65 19.35q-1.225-1.225-1.938-2.863T3 13q0-1.85.713-3.488T5.65 6.65q1.225-1.225 2.863-1.938T12 4q1.55 0 2.975.5t2.675 1.45l.7-.7q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-.7.7Q20 8.6 20.5 10.025T21 13q0 1.85-.713 3.488T18.35 19.35q-1.225 1.225-2.863 1.938T12 22Zm0-2q2.9 0 4.95-2.05T19 13q0-2.9-2.05-4.95T12 6Q9.1 6 7.05 8.05T5 13q0 2.9 2.05 4.95T12 20Zm0-7Z"/></svg>
                            <TiempoRestante fecha={fechaFin} />
                        </div>
                    </div>
                </div>
                {
                    seccion === 'tv' &&
                    <div className='zonaQr show'>
                        QR
                    </div>
                }
            </div>
            <div className='zonaProgreso'>
                <div className='barraProgreso'>
                    <div className='progreso' style={{ "--target": (((plazasOcupadas * 100) / plazasTotales)) + "%" }}></div>
                </div>
            </div>
        </div>
    )
}

const TiempoRestante = ({ fecha }) => {

    const restante = (f) => {
        const fechaSeparada = (f.split("T")[0]).split("-")
        const ahora = new Date()
        const fechaTiempo = new Date(f)
        const diferenciaMilisegundos = fechaTiempo - ahora
        const diferenciasHoras = diferenciaMilisegundos / 3600000

        let fechaMostrar = ''

        if(diferenciaMilisegundos <= 0) {
            fechaMostrar = 'Finalizado'
        } else {
            fechaMostrar = fechaSeparada[2] + "/" + fechaSeparada[1] + "/" + fechaSeparada[0]
            if(diferenciasHoras < 48){
                const horas = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60))
                const minutos = Math.floor((diferenciaMilisegundos % (1000 * 60 * 60)) / (1000 * 60))
                const segundos = Math.floor((diferenciaMilisegundos % (1000 * 60)) / 1000)

                if(horas == 0 && minutos == 0){
                    fechaMostrar = segundos + "s"
                } else if(horas == 0) {
                    fechaMostrar = minutos + "m " + segundos + "s"
                } else {
                    fechaMostrar = horas + "h " + minutos + "m " + segundos + "s"
                }
            }
        }

        return fechaMostrar
    }

    const [fechaRestante, setFechaRestante] = useState(restante(fecha))

    useEffect(() => {
        const intervalo = setInterval(() => {
            setFechaRestante(restante(fecha))
        }, 1000)

        return () => clearInterval(intervalo)
    }, [])

    return <label className='restante'>{ fechaRestante }</label>
}

export default Plazas
