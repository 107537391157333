export const diaSemana = (fechaString) => {
    const diasSemana = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB']
    const fecha = new Date(fechaString)
    return diasSemana[fecha.getUTCDay()]
}

export const mes = (fechaString) => {
    const mesesAbreviados = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
    const fecha = new Date(fechaString)
    return mesesAbreviados[fecha.getUTCMonth()]
}