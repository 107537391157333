import React, { useState, useEffect } from 'react'
import axios from 'axios'
import io from 'socket.io-client'

const EtiquetadosDiapositiva = ({ inscritos, totalJornadas, jornadaId, restoJornadas, calculo, compId }) => {

    const [etiquetados, setEtiquetados] = useState(inscritos)
    let inscritosId = []

    useEffect(() => {
        // Conectamos con el socket
        const socket = io('https://ws.nextcaddy.com:29920')
        // Escuchamos los resultados de esta competicion
        socket.on('new message', (response) => {
            if(response.message.tipo === "resultado" && response.message.competicion === parseInt(compId)){
                // Se hace la recarga si el inscrito que ha introducido el resultado esta entre los etiquetados de este componente
                // o si el contenido es "all" que significa que se recarga siempre
                if(response.message.inscritos === "all"){
                    reload()
                } else if(response.message.inscritos.length > 0){
                    for (const { id } of inscritos) {
                        if(response.message.inscritos.includes(Number(id))){
                            reload()
                            break
                        }
                    }
                }
            }
        })

        return () => socket.disconnect()
    })

    const reload = () => {
        axios.post(window.routeInfo.etiquetadosReload, {
            'inscritos': inscritosId,
            'jornadaId': jornadaId,
            'restoJornadas': restoJornadas,
            'calculo': calculo
        })
        .then((response) => {
            setEtiquetados(response.data)
        })
    }

    return etiquetados.map(({ nombre, contrapar, contraparTotal, hoyo, horaSalida, tee, id }, i) => {
        inscritosId.push([{
            'id': id,
            'nombre': nombre,
            'horaSalida': horaSalida,
            'tee': tee
        }])

        let clase1 = 'persona'
        if(contrapar > 0){
            clase1 += ' sobrePar'
        } else if(contrapar < 0){
            clase1 += ' bajoPar'
        }

        let situacion = ''
        if(hoyo){
            if(hoyo == "F"){
                situacion = hoyo
            } else {
                situacion = 'H' + hoyo
                if(tee != 1){
                    situacion += '*'
                }
            }
        } else {
            situacion = horaSalida
        }

        let c = ''
        if(contrapar > 0){
            c = '+'
        }
        if(contrapar == 0){
            c += 'P'
        } else {
            c += contrapar
        }

        let clase2 = 'personaTotal'
        if(contraparTotal > 0){
            clase2 += ' sobrePar'
        } else if(contraparTotal < 0) {
            clase2 += ' bajoPar'
        }

        let ct = ''
        if(contraparTotal > 0){
            ct = '+'
        }
        if(contraparTotal == 0){
            ct += 'P'
        } else {
            ct += contraparTotal
        }

        return <div className={clase1} key={i}>
            <div className='personaHoyo'>{ situacion }</div>
            <div className='personaJugador'>{ nombre }</div>
            {
                totalJornadas > 1 && contrapar != null && contrapar != contraparTotal &&
                    <div className='personaHoy'>{ c }</div>
            }
            {
                contraparTotal != null &&
                    <div className={clase2}>{ ct }</div>
            }
        </div>
    })
}

export default EtiquetadosDiapositiva