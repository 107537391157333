import React, { useState, useRef } from 'react'
import "../styles/SearchPanel.css"
import axios from 'axios'
import { diaSemana, mes } from "../util/formatoFecha"

const SearchPanel = ({
    searchPath,
    tournament,
    club
}) => {
    const [competiciones, setCompeticiones] = useState([])
    const [clubes, setClubes] = useState([])
    const timerRef = useRef(null)
    let value = ''

    const escribiendo = (e) => {
        value = e.target.value

        // Timeout para evitar que se haga una llamada cada vez que se escribe en el buscador
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        
        timerRef.current = setTimeout(busqueda, 500)
    }

    const busqueda = () => {
        if ("" !== value) {
            axios.post(searchPath, {
                'text': value
            })
            .then((response) => {
                setCompeticiones(response.data.competiciones)
                setClubes(response.data.clubes)
            })
        } else {
            // Clean results if nothing is written
            setCompeticiones([])
            setClubes([])
        }
    }

    return(
        <div className="searchPanel" id="sp">
            <fieldset className="search-container">
                <input type="text" onChange={escribiendo} placeholder="Buscar torneos o campos..." className="search" id="sip" />
                <div className="icons-container">
                    <div className="icon-search"></div>
                    <div className="icon-close">
                        <div className="x-up"></div>
                        <div className="x-down"></div>
                    </div>
                </div>
            </fieldset>
            <div className="resultsPanel">
                {
                    competiciones.length > 0 &&
                        <details>
                            <summary>
                                { competiciones.length }
                                <span>
                                      Torneos
                                </span>
                            </summary>
                            <div className="content">
                                <ul>
                                    {
                                        competiciones.map(({ id, nombre, fecha, nombreClub }, i) => {

                                            return <a href={tournament.replace("0", id)} className="enlace" key={i}>
                                                    <li className='lista'>
                                                        <div className="liCalendar">
                                                            <div className="calendarContainer">
                                                                <div className="dateWeekDay">{ diaSemana(fecha) }</div>
                                                                <div className="dateLower">
                                                                    <div className="statistic">
                                                                        <span className="day longShadow">{ fecha.split("-")[2] }</span>
                                                                        <span className="month longShadow">{ mes(fecha) }</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="liData">
                                                            <label>{ nombre }</label>
                                                            <div className="contentClub">
                                                                { nombreClub }
                                                            </div>
                                                        </div>
                                                    </li>
                                                </a>
                                        })
                                    }
                                    {
                                        competiciones.length == 0 &&
                                            <li>
                                                <label>No hay resultados</label>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </details>
                }

                {
                    clubes.length > 0 &&
                        <details>
                            <summary>
                                { clubes.length }
                                <span>
                                      Clubes
                                </span>
                            </summary>
                            <div className="content">
                                <ul>
                                    {
                                        clubes.map(({ id, nombre }, i) => {

                                            return <a href={club.replace("XXXX", id)} className="enlace" key={i}>
                                                <li className='lista'>
                                                    <div className="liData">
                                                        { nombre }
                                                        {/* <div className="contentClub">
                                                            Real Club de Golf de Sevilla
                                                        </div> */}
                                                    </div>
                                                </li>
                                            </a>
                                        })
                                    }
                                    {
                                        clubes.length == 0 &&
                                            <li>
                                                <label>No hay resultados</label>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </details>
                }
            </div>

       
        </div>
    )
}

export default SearchPanel