import React from "react"

const Tarjeta = ({ hora, tee, jugadores }) => {

    //Función que devuelve el nombre de la clase css para cambiar lo que ocurre en el marcador
    const calcularClaseEstado = (h) => {
        let horaActual = new Date()
        const [hour, min] = h.split(":")
        const fechaAlmacenada = new Date(
            horaActual.getFullYear(),
            horaActual.getMonth(),
            horaActual.getDate(),
            hour,
            min
        )
        
        const diferenciaEnMilisegundos = fechaAlmacenada - horaActual
        const diferenciaEnMinutos = diferenciaEnMilisegundos / 60000

        if (diferenciaEnMinutos <= 6 && diferenciaEnMinutos > 2) {
            return "match next"
        } else if (diferenciaEnMinutos <= 2 && diferenciaEnMinutos > 0) {
            return "match next inmediate"
        } else if (diferenciaEnMinutos <= 0) {
            return "match next inmediate animation"
        } else {
            return "match"
        }
    }

    return (
        // Clase css que controla el estado de la tarjeta
        <div className={calcularClaseEstado(hora)}>
            <div className="data">
                <div className="time">
                    { hora }
                    <div className="tee">Tee { tee }</div>
                </div>

                <div className="players">
                    {
                        jugadores.map((nombres, i) => {
                            return nombres.map((nombre, x) => {
                                return (
                                    <div className="player" key={x+""+i}>
                                        { nombre }
                                    </div>
                                )
                            })
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export default Tarjeta